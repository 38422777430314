import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Logo from '../../img/logo.png';
import API from '../../jsx/backend';

function Header() {
  const [auth, setAuth] = useState(false);
  const [logo, setLogo] = useState('');
  const [name, setName] = useState('');

useEffect(() => {
  API.get(`?branding=header`)
  .then((res) => {
    setName(res.data.name);
    setLogo(res.data.logo);
  })
  .catch((error) => {
  setName('SM Portal');
  setLogo(Logo);
  })
    if (window.sessionStorage.getItem('token')) {
      setAuth(true);
    }
}, []);


const logout = () => {
if (window.sessionStorage.getItem('token')) {
  window.sessionStorage.removeItem('token');
  setAuth(false);
}};

  return (
  <header className="smp-top header">
<div className="smp-container">
<Link to={"/"} className="logo">
{logo
  ?<img src={ logo } alt={ name } style={{height: "40px"}}/>
  :<i className="fas fa-spinner smp-spin"></i>
}
</Link>
  <input className="menu-btn" type="checkbox" id="menu-btn" />
  <label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
  <ul className="menu">
   {auth
? <>
<li><Link to={"/"}><i className="fas fa-tachometer-average"></i> DASHBOARD</Link></li>
<li><Link to={"/profile/"}><i className="fas fa-user-cog"></i> ACCOUNT</Link></li>
<li onClick={logout} ><Link to={"/auth/logout/"}><i className="fas fa-sign-out smp-text-red"></i> LOGOUT</Link></li>
  </>
: <>
<li><Link to={"/"}><i className="fas fa-home"></i> HOME</Link></li>
<li><Link to={"/updates/"}><i className="fas fa-calendar"></i> UPDATES</Link></li>
<li><Link to={"/auth/login/"}><i className="fas fa-sign-in smp-text-green"></i> LOGIN</Link></li>
</>
}
 </ul>
</div>
</header>
  );
}

export default Header;