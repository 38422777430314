import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../includes/loader';
import Error from '../includes/error';
import API from '../../jsx/backend';
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import InputHints from 'react-input-hints';

const Posts = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [processing, setProcessing] = useState(false);
const [phrase, setPhrase] = useState('');
const [title, setTitle] = useState('Please Wait');
const [tip, setTips] = useState('');
const [results, setResults] = useState(false);

useEffect(() => {
API.get(`?posts=list`)
.then((res) => {
setTitle("School Updates");
setTips(res.data.posts);
setLoading(false);
})
.catch((error) => {
setTitle("Loading Failed");
setTips("");
setLoading(false);
setError(true);
})}, []);

const forPhrase = e => {
  setPhrase(e.target.value);
}

const handleSearch = (event) => {
  event.preventDefault();
  setProcessing(true);
  setError(false);
  const searchFormDataNew = new FormData();
  searchFormDataNew.append("phrase", phrase);
  searchFormDataNew.append("posts", "search");
  API.post(`/`, searchFormDataNew)
  .then((response) => {
  setTips(response.data.posts);
  setResults(true);
  setProcessing(false);
  })
  .catch((error) => {
  setError(true);
  setProcessing(false);
  })}

  const handleReset = (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(false);
    setResults(false);
  API.get(`?posts=list`)
  .then((res) => {
  setTips(res.data.posts);
  setProcessing(false);
  })
  .catch((error) => {
  setTips("");
  setProcessing(false);
  setError(true);
  })}

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>{ title } - SM Portal</title>
</Helmet>

{loading
? <div className="smp-section">
  <div className="smp-container">
  <Loader />
  </div>
  </div>
: <div className="smp-section">
{error
? <div className="smp-container">
  <Error />
  </div>
: <>
<div className="smp-container smp-padding">
<form className="smp-white smp-container smp-padding smp-border smp-border-grey" style={{borderRadius: "10px"}} onSubmit={handleSearch}>
<div className="smp-col s9">
<InputHints type="text" value={phrase} onChange={forPhrase} className="smp-input smp-border-0" style={{borderRadius: "10px 0 0 10px", width: "100%", outline: "none"}} placeholders={ ['Type search phrase...', 'School terms...', 'General meeting...', 'Consultation day...'] }  required/>
</div>
<div className="smp-col s3">
{results
?<button type="button" onClick={handleReset} className="smp-btn smp-black smp-border-0" style={{borderRadius: "10px", width: "100%"}}><i className="fas fa-times"></i> <span className="smp-hide-small smp-hide-medium">RESET FILTER</span></button>
:<button type="submit" className="smp-btn smp-green smp-border-0" style={{borderRadius: "10px", width: "100%"}}><i className="fas fa-search"></i> <span className="smp-hide-small smp-hide-medium">SEARCH UPDATES</span></button>
}
</div>
</form>
<div style={{ paddingTop: "20px" }}></div>
</div>
<div className="smp-container smp-row-padding" style={{paddingBottom: "50px"}}>
{processing
? <Loader />
:<>
{tip
? <>
{tip.map((post) => (
<div className="smp-quarter" style={{borderRadius: "10px 10px 0 0"}}>
 <br />   
<Link to={`/updates/${post.url }/`} style={{textDecoration: "none"}} title={post.description }>
<div className="smp-card" style={{
      backgroundImage: 'url(' + post.image + ')',
      height: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%", borderRadius: "10px 10px 0 0"
    }}>
<div className="smp-padding smp-display-container" style={{backgroundColor: "#ffffffe3 ", minHeight: "350px", borderRadius: "10px 10px 0 0"}}>
<h3 className="smp-display-top smp-center">{ post.title  }</h3>
</div>
<button className="smp-btn smp-green" style={{width: "100%"}}>READ MORE</button>
</div>
</Link>
</div>
))}
</>
: <h4 className="smp-center" style={{paddingTop: "50px"}}>We have no post suggestions!</h4>
}
</>
}
</div>
</>
}
  </div>
}
<Top />
</div>
);}

export default Posts;