import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../web/sections/header";
import Footer from "../web/sections/footer";

import Home from "../web/pages/home";
import NotFound from "../web/pages/404";
import Login from "../web/pages/login";
import Reset from "../web/pages/reset";
import Recover from "../web/pages/recover";
import Register from "../web/pages/register";
import Logout from "../web/pages/logout";
import Posts from "../web/pages/posts";
import Post from "../web/pages/post";

class App extends Component {
render() {
return (
<Router>
<Header />
<Routes>
<Route exact path="/" element={<Home/>} />
<Route exact path="/auth/register/" element={<Register/>} />
<Route exact path="/auth/login/" element={<Login/>} />
<Route exact path="/auth/logout/" element={<Logout/>} />
<Route exact path="/auth/reset/" element={<Reset/>} />
<Route exact path="/auth/recover/:email/:secret/" element={<Recover/>} />
<Route exact path="/updates/" element={<Posts/>} />
<Route exact path="/updates/:post/" element={<Post/>} />


<Route path="*" element={<NotFound/>} />
</Routes>
<Footer />
</Router>
);}}
 
export default App;