import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../includes/loader';
//import Error from '../includes/error';
//import API from '../../jsx/backend';
import Top from '../includes/topping';

const Logout = () => {
const [auth, setAuth] = useState(false);

React.useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
        setAuth(true);
        window.sessionStorage.removeItem('token');
        window.location = "/";
    }else{
      setAuth(false);
      window.location = "/";
    }
}, []);

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Logout - SM Portal</title>
</Helmet>

{auth
? <div className="smp-section">
  <div className="smp-container smp-content smp-center">
<Loader />
  </div>
  </div>
: <div className="smp-section">
  <div className="smp-container smp-content smp-center">
<Loader />
  </div>
</div>
}
<Top />
</div>
);}

export default Logout;