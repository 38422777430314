import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../includes/loader';
import Error from '../includes/error';
import API from '../../jsx/backend';
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import Greeting from '../includes/greeting';
import BgD from '../../img/background.jpg';
import Updates from '../sections/updates';

const Home = () => {
const [title, setTitle] = useState('Welcome');
const [auth, setAuth] = useState(false);
const [error, setError] = useState(false);
const [loading, setLoading] = useState(true);
const [loadings, setLoadings] = useState(true);
const [role, setRole] = useState('0');
const [teachers, setTeachers] = useState('-1');
const [parents, setParents] = useState('-1');
const [students, setStudents] = useState('-1');
const [admins, setAdmins] = useState('-1');
const [applicants, setApplicants] = useState('');
const [Bg, setBack] = useState('');
const [names, setName] = useState('');

useEffect(() => {
  API.get(`?branding=home`)
  .then((res) => {
    setName(res.data.name);
    setBack(res.data.background);
  })
  .catch((error) => {
  setName('SM Portal');
  setBack(BgD);
  })

    if (window.sessionStorage.getItem('token')) {
      const contentFormDataNew = new FormData();
      contentFormDataNew.append("token",window.sessionStorage.getItem('token'));
      setAuth(true);
      setTitle('Please Wait');
      API.post(`/`, contentFormDataNew)
      .then((res) => {
      sessionStorage.setItem("token", res.data.token);
      setRole(res.data.role);
        setLoading(false);
      if(res.data.status === "1"){
        setTitle('Dashboard');
        setRole(res.data.role);
      if(res.data.role === "1") {
        const statsFormDataNew = new FormData();
        statsFormDataNew.append("token",window.sessionStorage.getItem('token'));
        statsFormDataNew.append("do","dashboard");
        API.post(`/`, statsFormDataNew)
        .then((response) => {
          sessionStorage.setItem("token", response.data.token);
          setTeachers(response.data.teachers);
          setParents(response.data.parents);
          setStudents(response.data.students);
          setAdmins(response.data.admins);
          setApplicants(response.data.applications.applicants);
          setLoading(false);
          setLoadings(false);
      })
      .catch((error) => {
        setTitle('Not Responding');
        setLoading(false);
        setError(true);
        })
      }else{
        setLoading(false);
      }}else{
        window.sessionStorage.removeItem('token');
        window.location = "/";
      }})
      .catch((error) => {
      setTitle('Not Responding');
      setLoading(false);
      setError(true);
      })}else{
      setLoading(false);
    }}, []);

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>{ title } - SM Portal</title>
</Helmet>

{loading
? <Loader />
:<>
{auth
? <div className="smp-section">
{!error
? <>
{role === "1"
?<>
<div className="smp-row-padding smp-container">
<div className="smp-quarter smp-padding">
<Link to="/admin/teachers/">
<div className="smp-display-container smp-grey" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-user-group fa-3x"></i>
<h4>TEACHERS</h4>
<p>{teachers >= 0
?<>{ teachers }</>
:<i className="fas fa-spinner smp-spin"></i>
}</p>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/admin/students/">
<div className="smp-display-container smp-light-grey" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-users fa-3x"></i>
<h4>STUDENTS</h4>
<p>{students >= 0
?<>{ students }</>
:<i className="fas fa-spinner smp-spin"></i>
}</p>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/admin/users/">
<div className="smp-display-container smp-pale-green" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-user-shield fa-3x"></i>
<h4>ADMINS</h4>
<p>{admins >= 0
?<>{ admins }</>
:<i className="fas fa-spinner smp-spin"></i>
}</p>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/admin/parents/">
<div className="smp-display-container smp-sand" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-shield fa-3x"></i>
<h4>PARENTS</h4>
<p>{parents >= 0
?<>{ parents }</>
:<i className="fas fa-spinner smp-spin"></i>
}</p>
</span>
</div>
</Link>
</div>
</div>
<br />
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>SYSTEM MANAGEMENT</span></h5>
</div>
<div className="smp-row-padding">
<div className="smp-quarter smp-padding">
<Link to="/admin/classes/">
<div className="smp-display-container smp-green" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-display fa-3x"></i>
<h4>CLASSES</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/admin/subjects/">
<div className="smp-display-container smp-black" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-sliders fa-3x"></i>
<h4>SUBJECTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/admin/publishing/">
<div className="smp-display-container smp-blue" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-newspaper fa-3x"></i>
<h4>PUBLISHING</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/admin/settings/">
<div className="smp-display-container smp-orange" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-cogs fa-3x"></i>
<h4>SETTINGS</h4>
</span>
</div>
</Link>
</div>
</div>
</div>
<br />
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>MOST RECENT APPLICATIONS</span></h5>
</div>
{loadings
? <Loader />
: <>
{applicants
? <>
{applicants.map((applicant) => (
<div className="smp-panel smp-border" style={{borderRadius: "10px"}}>
<p>{ applicant.name }<Link to={`/admin/students/${ applicant.reference }/`} className="smp-right smp-text-blue" style={{textDecoration: "none"}}>VIEW APPLICATION</Link></p>
</div>
))}
</>
: <h4 className="smp-center" style={{paddingTop: "150px", paddingBottom: "150px"}}>We have no new applicants!</h4>
}
</>
}
</div>
</>
:<>
{role === "2"
?<>
<div className="smp-container">
<div className="smp-row-padding">
<div className="smp-quarter smp-padding">
<Link to="/teacher/students/">
<div className="smp-display-container smp-green" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-users fa-3x"></i>
<h4>STUDENTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/teacher/classes/">
<div className="smp-display-container smp-black" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-display fa-3x"></i>
<h4>CLASSES</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/teacher/subjects/">
<div className="smp-display-container smp-blue" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-sliders fa-3x"></i>
<h4>SUBJECTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/teacher/marking/">
<div className="smp-display-container smp-orange" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-check fa-3x"></i>
<h4>MARKING</h4>
</span>
</div>
</Link>
</div>
</div>
</div>
<br />
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>SCHEDULE</span></h5>
</div>
</div>
</>
:<>
{role === "3"
?<>
<div className="smp-container">
<div className="smp-row-padding">
<div className="smp-quarter smp-padding">
<Link to="/parent/students/">
<div className="smp-display-container smp-green" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-users fa-3x"></i>
<h4>STUDENTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/parent/tuition/">
<div className="smp-display-container smp-black" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-file-invoice-dollar fa-3x"></i>
<h4>TUITION</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/parent/results/">
<div className="smp-display-container smp-blue" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-asterisk fa-3x"></i>
<h4>RESULTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/parent/applications/">
<div className="smp-display-container smp-orange" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-pencil fa-3x"></i>
<h4>APPLICATIONS</h4>
</span>
</div>
</Link>
</div>
</div>
</div>
<br />
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>ANNOUNCEMENTS</span></h5>
</div>
</div>
</>
:<>
{role === "4"
?<>
<div className="smp-container">
<div className="smp-row-padding">
<div className="smp-quarter smp-padding">
<Link to="/student/subjects/">
<div className="smp-display-container smp-green" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-sliders fa-3x"></i>
<h4>SUBJECTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/student/assignments/">
<div className="smp-display-container smp-black" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-file-pen fa-3x"></i>
<h4>ASSIGNMENTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/student/results/">
<div className="smp-display-container smp-blue" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-asterisk fa-3x"></i>
<h4>RESULTS</h4>
</span>
</div>
</Link>
</div>
<div className="smp-quarter smp-padding">
<Link to="/student/payments/">
<div className="smp-display-container smp-orange" style={{borderRadius: "10px", height: "150px"}}>
<span className="smp-display-middle smp-center">
<i className="fas fa-money-bill fa-3x"></i>
<h4>PAYMENTS</h4>
</span>
</div>
</Link>
</div>
</div>
</div>
<br />
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>ANNOUNCEMENTS</span></h5>
</div>
</div>
</>
:<>
<Error />
</>
}
</>
}
</>
}
</>
}
</>
: <Error />
}
</div>
: <div className="smp-section">
<div style={{ marginTop: "-20px" }}>
<div  style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="smp-padding" style={{backgroundColor: "#ffffffe3 ", minHeight: "350px"}}>
<h1 className="smp-center">WELCOME GUEST, <Greeting />!</h1>
<p className="smp-center">This is the official portal for {names
?<> { names } </>
: <i className="fas fa-spinner smp-spin"></i>
 }. You can login as a student, parent, teacher or admin.</p>
<div style={{paddingTop: "60px"}}></div>
<div className="smp-section smp-container">
<div className="smp-row-padding">
<div className="smp-half smp-padding">
<Link to="/auth/login/"><button className="smp-btn smp-green" style={{width: "100%", borderRadius: "10px"}}>ACCOUNT LOGIN</button></Link>
</div>
<div className="smp-half smp-padding">
<Link to="/auth/register/"><button className="smp-btn smp-black" style={{width: "100%", borderRadius: "10px"}}>PARENT REGISTRATION</button></Link>
</div>
</div>
</div>
<div style={{paddingTop: "20px"}}></div>
</div>
</div>
</div>
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>LATEST SCHOOL UPDATES</span></h5>
</div>
<Updates />
</div>
</div>
}
</>
}
<Top />
</div>
);}

export default Home;