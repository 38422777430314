import React, { useState, useEffect } from 'react';
import API from '../../jsx/backend';

const Footer = () => {
const [visible, setVisible] = useState(false);
const [year, setYear] = useState('');
const [names, setName] = useState('');

useEffect(() => {
    API.get(`?branding=footer`)
    .then((res) => {
      setName(res.data.name);
      setYear(res.data.established);
    })
    .catch((error) => {
    setName('SM Portal');
    setYear("2022");
    })}, []);

const toggleVisible = () => {
const scrolled = document.documentElement.scrollTop;
if (scrolled > 80){
setVisible(true)
} else if (scrolled <= 80){
setVisible(false)
}};
const scrollToTop = () =>{
window.scrollTo({
top: 0, 
behavior: 'smooth'
});};
window.addEventListener('scroll', toggleVisible);

return (
<footer className="smp-container smp-center foot smp-light-grey" >
<p>&copy;
{year
?<>{ year } </>
: <i className="fas fa-spinner smp-spin"></i>
 } - {new Date().getFullYear()} 
 {names
?<> { names } </>
: <i className="fas fa-spinner smp-spin"></i>
 } - All Rights Reserved</p>
<button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} className="smp-scroll-up smp-black smp-btn" title="Scroll Up"><i className="fas fa-arrow-up"></i></button>
</footer>
)};

export default Footer;