import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import Loader from '../includes/loader';
import API from '../../jsx/backend';
import Error from '../includes/error';
import Greeting from '../includes/greeting';
import Updates from '../sections/updates';

const Reset = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (window.sessionStorage.getItem('token')) {
            window.location = "/";   
        }
    }, []);

    const handleReset = (e) => {
        e.preventDefault();
        setLoading(true);
        const resetFormDataNew = new FormData();
        resetFormDataNew.append("email",email);
        resetFormDataNew.append("action","reset");
        
        API.post(`/`, resetFormDataNew)
        .then((response) => {
   switch(response.data.status){
  case "1":
    setSuccess(true);
    setLoading(false);
    setTimeout(function(){
        window.location = "/";
      }, 3000);
  break;

  default:
    setFail(true);
    setLoading(false);
    setTimeout(function(){
        window.location = "/auth/reset/";
      }, 3000);
}
        })
        .catch((error) => { 
            setError(true);
            setLoading(false);
        })}


return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Reset Password - SM Portal</title>
</Helmet>

<div className="smp-section smp-container">
  <form onSubmit={handleReset}>
  <div className="smp-container">
  {error
? <>
<Error />
</>
: <>
{success
? <div className="smp-center smp-text-green">
<h1>PASSWORD LINK SENT</h1>
<p>Please check your email for your password reset link. Check your inbox or SPAM folder for this email.</p>
<br />
<i className="fas fa-spinner smp-spin fa-7x"></i>
</div>
: <>
{fail
? <div className="smp-center smp-text-red">
<h1>UNKNOWN ACCOUNT</h1>
<br />
<i className="fas fa-spinner smp-spin fa-7x"></i>
</div>
: <>
{!loading
? <div className="smp-container smp-row-padding">
<div className="smp-half smp-center smp-white smp-card smp-padding" style={{borderRadius: "10px"}}>
  <h4><Greeting />, RESET PASSWORD</h4>
  <hr />
  <label for="email" className="smp-left">EMAIL ADDRESS</label>
  <input type="email" id="email" className="smp-input smp-border" style={{width: "100%", borderRadius: "10px" }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email address..." required />
  <br />
  <input type="submit" className="smp-input smp-button smp-green smp-border-0" style={{width: "100%", borderRadius: "10px" }} value="SEND PASSWORD LINK" />
  </div>
  <div className="smp-half smp-padding smp-center">
<br />
<i className="fas fa-key fa-6x smp-text-grey"></i>
<br />
  <div className="smp-container smp-padding">
  <Link to="/auth/login/"><button type="button" className="smp-tiny smp-button smp-pale-green" style={{width: "100%", borderRadius: "10px" }}>ACCOUNT LOGIN</button></Link>
  </div>
  <div className="smp-container smp-padding">
  <Link to="/auth/register/"><button type="button" className="smp-tiny smp-button smp-black" style={{width: "100%", borderRadius: "10px" }}>REGISTER PARENT</button></Link>
  </div>
  </div>
  </div>
: <>
  <Loader />
  </>
  }
  </>
}
</>
}
</>
}
  <br />
  </div>
  </form>
</div>
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>LATEST SCHOOL UPDATES</span></h5>
</div>
<Updates />
</div>
<Top />
</div>
);}

export default Reset;