import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Greeting from '../includes/greeting';
import { Link } from "react-router-dom";
import Top from '../includes/topping';
import Loader from '../includes/loader';
import API from '../../jsx/backend';
import Error from '../includes/error';
import Updates from '../sections/updates';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [fullname, setName] = useState('');
  const [email, setEmail] = useState('');
  const [duplicate, setDuplicate] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fail, setFail] = useState(false);

   useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
        window.location = "/";   
    }
}, []); 

  const handleRegister = (e) => {
  e.preventDefault();
  setLoading(true);
  const registerFormDataNew = new FormData();
  registerFormDataNew.append("fullname",fullname);
  registerFormDataNew.append("email",email);
  registerFormDataNew.append("action","register");

  API.post(`/`, registerFormDataNew)
  .then((response) => {
switch(response.data.status){
  case "1":
    setSuccess(true);
    setLoading(false);
    setTimeout(function(){
      window.location = "/auth/login/";
    }, 10000);
  break;

  case "2":
    setDuplicate(true);
    setLoading(false);
    setTimeout(function(){
      window.location = "/auth/register/";
    }, 5000);
  break;

  default:
    setFail(true);
    setLoading(false);
    setTimeout(function(){
      window.location = "/auth/register/";
    }, 5000);
}
 
  })
  .catch((error) => { 
  setError(true);
  setLoading(false);
  })}

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Parent Registration - SM Portal</title>
</Helmet>

<div className="smp-section smp-container">
  <form onSubmit={handleRegister}>
  <div className="smp-container">
{error
? <>
<Error />
</>
: <>
{success
? <div className="smp-center smp-text-green">
<h1>ACCOUNT CREATED SUCCESSFULLY</h1>
<p>Please check your email for your login password. If you do not find it in your inbox, please check your SPAM folder.</p>
<br />
<i className="fas fa-spinner smp-spin fa-7x"></i>
</div>
: <>
{duplicate
? <div className="smp-center smp-text-orange">
<h1>DUPLICATE ACCOUNT</h1>
<br />
<i className="fas fa-spinner smp-spin fa-7x"></i>
</div>
: <>
{fail
? <div className="smp-center smp-text-red">
<h1>REGISTRATION FAILED</h1>
<br />
<i className="fas fa-spinner smp-spin fa-7x"></i>
</div>
: <>
{!loading
? <div className="smp-container smp-row-padding">
<div className="smp-half smp-center smp-white smp-card smp-padding" style={{borderRadius: "10px"}}>
  <h4><Greeting />, REGISTER PARENT</h4>
  <hr />
  <label for="fullname" className="smp-left">FULL NAME</label>
  <input type="text" id="fullname" className="smp-input smp-border" style={{width: "100%", borderRadius: "10px" }} value={fullname} onChange={(e) => setName(e.target.value)} placeholder="Your full name..." required />
  <br />
  <label for="email" className="smp-left">EMAIL ADDRESS</label>
  <input type="email" id="email" className="smp-input smp-border" style={{width: "100%", borderRadius: "10px" }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email address..." required />
  <br />
  <input type="submit" className="smp-input smp-button smp-green smp-border-0" style={{width: "100%", borderRadius: "10px" }} value="REGISTER NOW" />
  </div>
  <div className="smp-half smp-padding smp-center">
<br />
<i className="fas fa-user-plus fa-10x smp-text-grey"></i>
<br />
  <div className="smp-container smp-padding">
  <Link to="/auth/login/"><button type="button" className="smp-tiny smp-button smp-pale-green" style={{width: "100%", borderRadius: "10px" }}>ACCOUNT LOGIN</button></Link>
  </div>
  <div className="smp-container smp-padding">
  <Link to="/auth/reset/"><button type="button" className="smp-tiny smp-button smp-black" style={{width: "100%", borderRadius: "10px" }}>RESET PASSWORD</button></Link>
  </div>
  </div>
  </div>
: <>
  <Loader />
  </>
  }
</>
}
</>
}
</>
}
</>
}
  <br />
  </div>
  </form>
</div>
<br />
<div className="smp-container">
<div class="smp-heading smp-opacity">
<h5><span>LATEST SCHOOL UPDATES</span></h5>
</div>
<Updates />
</div>
<Top />
</div>
);}

export default Register;